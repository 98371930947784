
import { Component, Vue } from "vue-property-decorator";
import BaseButton from "@/components/UI/BaseButton.vue";
import BaseIcon from "@/components/UI/BaseIcon.vue";

@Component({
  components: { BaseButton, BaseIcon },
})
export default class Footer extends Vue {
  openWindow(link: string): void {
    window.open(link);
  }
}
