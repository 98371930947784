import Vue from 'vue';
import VueRouter from 'vue-router';
import MainLayout from '@/layouts/MainLayout.vue'

Vue.use(VueRouter);


const routes = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    name: 'Home',
    component: MainLayout,
    children: [
      {
        path: '/',
        component: () => import('@/views/HomePage.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(): any {
    const app: any = document.getElementById('app')
    app.scrollIntoView();
  },
  routes,
});

export default router;