
import { Component, Vue } from "vue-property-decorator";
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";

@Component({
  components: {
    TheHeader,
    TheFooter,
  },
})
export default class MainLayout extends Vue {
  scrollToRef = "";

  scrollTo(ref: string): void {
    this.scrollToRef = ref;
  }
}
