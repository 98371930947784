
import { Component, Vue } from "vue-property-decorator";
import BaseButton from "@/components/UI/BaseButton.vue";

@Component({
  components: { BaseButton },
})
export default class Header extends Vue {
  isShowMenu = false;

  linkToUpSwot(): void {
    window.open("https://upswot.com/");
  }

  bookPlace(): void {
    window.open(
      "https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=26a30926-fbe5-4a94-aa33-105e3e2cf64f&env=na3&acct=cb622100-d394-484d-8a5d-1a26d3933111&v=2"
    );
  }

  showMenu(): void {
    this.isShowMenu = !this.isShowMenu;
  }

  scrollTo(value: string): void {
    this.$emit("scroll", value);
    this.isShowMenu = false;
  }
}
